<script setup lang="ts">
/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * computed
 * ==================================================================
 */
const actor = computed(() => {
  return $actor.core.actor.value
})

const isSpoofing = computed(() => {
  return Boolean($actor.core.spoofing.value)
})

const fullName = computed(() => {
  return actor.value?.fullName || ''
})

/**
 * methods
 * ==================================================================
 */
function clearSpoof () {
  $actor.core.clearSpoof()
}
</script>

<template>
  <v-toolbar
    v-if="isSpoofing"
    color="violet"
    density="compact"
    elevation="0"
  >
    <v-toolbar-title>
      <span class="font-weight-bold text-white">
        Spoofing
      </span>
      <span class="text-white">
        {{ fullName }}
      </span>
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      icon
      color="white"
      @click="clearSpoof"
    >
      <v-icon>
        mdi-close
      </v-icon>
    </v-btn>
  </v-toolbar>
</template>
